<template>
  <div class="me" v-if="value && value.synopticComponent" :key="value.id">
    <TogglePanel
      :title="$tc('data', 1)"
      v-if="'data_id' in value"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down',
        before: 'glyphicon glyphicon-stats'
      }"
      persistent="synoptic_control_data_selection"
    >
      <!-- BEGIN of data selector -->
      <template #title>
        <span
          :class="value.data_id ? '' : 'text-danger'"
          :title="dataName || $tc('required', 1)"
        >
          {{ $tc("data", 1) }}
          <i
            class="fa fa-exclamation-triangle"
            :title="`${$t('invalid_data')}\n${$tc('required', 1)}`"
            v-if="!value.data_id"
          ></i>
        </span>
      </template>
      <ControlDataSelector
        v-if="value && value.id"
        :key="value.id"
        :value="value.data_id"
        :connectorId="value.connector_id"
        :parser="dataParser"
        :allowedTypes="allowedTypes"
        @input="updateDataId"
        @setExpression="buildExpression($event)"
        @connectorChanged="lastConnectorIdSelection = $event"
      />
      <!-- END of data selector -->

      <!-- BEGIN of data index -->
      <ValueSourceSelector
        v-if="dataValueIndex"
        v-model="dataValueIndex"
        :offset="true"
        :connectorId="selectedDataConnectorId"
        :exclude="[value.data_id]"
      >
        <template #label>
          <div>
            <label for="data_value_index"
              >{{ $t("titles.data_value_index") }}

              <ToolTip
                :title="
                  `${$t('hints.data_value_index')}<br/>${$tc(
                    'interval',
                    1
                  )}: &ge; 0 &amp;&amp; &le; ${dataMemorySize - 1}`
                "
              />
            </label>
            <div class="pull-right">
              <span
                class="small"
                v-if="dataValueIndex.type == 'constant'"
                :title="`${$t('numeric').toLowerCase()}`"
              >
                &ge; 0 &amp;&amp; &le;
                {{ dataMemorySize - 1 }}
                &nbsp;&nbsp;
              </span>
              <span
                class="small"
                v-else
                :title="
                  `${$t('numeric').toLowerCase()} ${$t(
                    'or'
                  ).toLowerCase()} ${$t(
                    'expression'
                  ).toLowerCase()}\nex: $value*10+3`
                "
              >
                <i class="fa fa-code"></i>
              </span>
            </div>
          </div>
        </template>
      </ValueSourceSelector>
      <!-- END of data index -->

      <!-- Begin of data simulator input -->
      <template v-if="value.data_id">
        <label for="">{{ $t("simulation") }}</label>
        <TestDataValue
          :dataId="value.data_id"
          :dataValueIndex="dataValueIndex"
          :key="testDataValue.counter"
          :disabled="testDataValue.disabled"
        />
      </template>
      <div v-if="$attrs.hasExpression">
        <ControlExpressionForm
          :dataId="value.data_id"
          :connectorId="value.connector_id || lastConnectorIdSelection"
          v-model="expression"
          hint="hints.expression_warning"
          ref="expForm"
        />
      </div>
      <!-- END of data simulator input -->
    </TogglePanel>
    <ControlStyleProperties
      v-if="value.synopticComponent && value.synopticComponent.style"
      :value="value.synopticComponent.style"
      :control="value"
      :applicable="true"
      @input="
        update({
          ...value,
          synopticComponent: {...value.synopticComponent, style: $event}
        })
      "
      style="margin-bottom: 12px"
    >
      <template #padding v-if="$slots.padding">
        <slot name="padding"></slot>
      </template>
    </ControlStyleProperties>
    <TogglePanel
      title="info"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down',
        before: 'fa fa-info'
      }"
      persistent="synoptic_control_info"
    >
      <div class="form-group form-group-sm">
        <label class="label-inline" for="editor-text-align"
          >{{ $t("description") }}
          <ToolTip
            :title="
              `${$t('hint')},
                    ${$t('description')}, ${$t('info')}`
            "
        /></label>
        <input
          :placeholder="`${$t('text')}`"
          class="form-control"
          v-model="hint"
        />
      </div>
    </TogglePanel>
    <TogglePanel
      title="permissions"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down',
        before: 'fa fa-key'
      }"
      persistent="synoptic_control_permissions"
    >
      <ProcessAreaPermissions
        id="process-area-permissions"
        v-model="permissions"
      />
    </TogglePanel>
  </div>
</template>

<script>
import GalleryItems from "@/assets/dashboard/controls.json";
import ControlDataSelector from "./control-data-selector";
import ControlStyleProperties from "./control-style-properties";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import TestDataValue from "./test-data-value.vue";
import ProcessAreaPermissions from "@/components/process-area-permissions";
import ValueSourceSelector from "@/components/editor/value-source-selector.vue";
import ToolTip from "@/components/tooltip.vue";
import {initialValue as DftDataValueIndex} from "@/components/editor/value-source-selector.vue";
import ControlExpressionForm from "@/components/synoptic/property-editor/controls/control-expression-form.vue";
export default {
  name: "ControlBaseProperties",
  components: {
    ControlDataSelector,
    ControlStyleProperties,
    TogglePanel,
    TestDataValue,
    ProcessAreaPermissions,
    ValueSourceSelector,
    ToolTip,
    ControlExpressionForm
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    dataParser: {
      type: [Function],
      required: false,
      default: null
    },
    allowedTypes: {
      type: Array,
      required: false,
      default: () => ["bool", "float", "int"]
    }
  },
  data() {
    return {
      gallery_items: GalleryItems,
      testDataValue: {
        disabled: false,
        counter: 0
      },
      lastConnectorIdSelection: ""
    };
  },
  computed: {
    hint: {
      set(value) {
        let control = JSON.parse(JSON.stringify(this.value));
        control.synopticComponent.hint = value;
        this.update(control);
      },
      get() {
        return this?.value?.synopticComponent?.hint || "";
      }
    },
    permissions: {
      set({view_permission, interaction_permission}) {
        let control = JSON.parse(JSON.stringify(this.value));
        control.synopticComponent.view_permission = view_permission;
        control.synopticComponent.interaction_permission = interaction_permission;
        delete control.synopticComponent.process_area_id;
        // removes permission from click event if it exists
        if (control.synopticComponent.on?.click?.process_area_id) {
          delete control.synopticComponent.on.click.process_area_id;
        }
        this.update(control);
      },
      get() {
        let clickPermission;
        // gets permission from click event if available
        // and if control is not for data writing
        if (
          this.value?.synopticComponent?.on?.click?.process_area_id &&
          !this.value.synopticComponent.componentName.includes("DataValue")
        ) {
          clickPermission = [
            this.value.synopticComponent.on.click.process_area_id
          ];
        }
        return {
          view_permission:
            this.value?.synopticComponent?.view_permission ??
            (this.value?.synopticComponent?.process_area_id
              ? [this.value?.synopticComponent?.process_area_id]
              : []),
          interaction_permission:
            this.value?.synopticComponent?.interaction_permission ??
            clickPermission ??
            []
        };
      }
    },
    selectedData() {
      return (
        (this?.value?.data_id &&
          (this.$store.getters["dashboard/extendedDataList"] || []).find(
            ({id}) => parseInt(id) == parseInt(this?.value?.data_id)
          )) ||
        null
      );
    },
    selectedDataConnectorId() {
      return this?.selectedData?.clp_id ?? "";
    },
    dataName() {
      return this.selectedData
        ? `${this.selectedData.id} - ${this.selectedData.name}`
        : this?.value?.data_id || "";
    },
    dataMemorySize() {
      return (this.selectedData && this.selectedData?.memory_size) || 1; // at least a position
    },
    dataValueIndex: {
      set(val) {
        if (!(this.dataMemorySize > 1) || !val) return;
        let entry = {...val};
        if (entry.type == "constant") {
          entry.value =
            entry.value !== "" && !isNaN(Number(entry.value))
              ? parseInt(entry.value)
              : -1;
          if (entry.value < 0) entry.value = -1;
          else if (entry.value >= this.dataMemorySize)
            entry.value = this.dataMemorySize - 1;
        }
        let control = JSON.parse(JSON.stringify(this.value));
        control.data_value_index = entry;
        this.$emit("input", control);
      },
      get() {
        if (!(this.dataMemorySize > 1)) return null;
        let entry = this?.value?.data_value_index || DftDataValueIndex();
        if (entry.type == "constant" && !(entry.value >= 0)) {
          entry.value = 0;
        }
        return entry;
      }
    },
    dashboardSimulation() {
      return this.$store.getters["dashboard/simulation"];
    },
    expression: {
      set(value) {
        let control = {
          ...this.value,
          synopticComponent: {
            ...this.value.synopticComponent,
            expression: value
          }
        };
        this.update(control);
      },
      get() {
        return this?.value?.synopticComponent?.expression || "";
      }
    }
  },
  watch: {
    dashboardSimulation: {
      handler(n) {
        this.testDataValue.disabled = n;
      },
      immediate: true
    }
  },
  methods: {
    async updateDataId(dataId, connectorId) {
      let control = JSON.parse(JSON.stringify(this.value));
      if (dataId && control && control.data_id != dataId) {
        let oData =
          control.data_id &&
          (this.$store.getters["dashboard/dataList"] || []).find(
            ({id}) => parseInt(id) == parseInt(control.data_id)
          );
        let data = (this.$store.getters["dashboard/dataList"] || []).find(
          ({id}) => parseInt(id) == parseInt(dataId)
        );
        // BEGIN Default data format

        if (data && data?.value_format_type?.id) {
          if (data.custom_format) {
            if (
              !oData ||
              !oData.value_format_type ||
              oData.value_format_type.id != data?.value_format_type?.id ||
              oData.custom_format != data.custom_format
            ) {
              control.format = data.custom_format;
            }
          } else if (
            this.$utils.trim(control?.synopticComponent?.expression ?? "") ===
            ""
          ) {
            let formatList = {};
            this.$root.config.references.data_value_format_types.forEach(
              (i) => {
                formatList[i.id] = i.format_mask;
              }
            );
            let format = formatList[data.value_format_type.id] || "";
            if (["text_list", "duration", "custom"].indexOf(format) == -1) {
              if (control.format) {
                // there is already a format assigned to the control.
                // does not change it if previous memory type is the same
                let prev_data = (
                  this.$store.getters["dashboard/dataList"] || []
                ).find((i) => i.id == control.data_id);
                if (
                  !prev_data ||
                  (prev_data?.memory_type?.id || "") !=
                    (data?.memory_type?.id || "") ||
                  (data?.unity_label &&
                    data?.unity_label != prev_data?.unity_label) ||
                  data?.device?.connector?.protocol?.is_mqtt_protocol ||
                  (prev_data?.device?.id ?? "") != (data?.device?.id ?? "")
                ) {
                  control.format = data?.unity_label
                    ? `${format} ${data.unity_label}`
                    : format;
                }
              } else {
                control.format = data?.unity_label
                  ? `${format} ${data.unity_label}`
                  : format;
              }
            } else {
              control.format = "";
            }
          }
        }
        // END Default data format

        // BEGIN data value index
        // it assigns the defined default data value index to the control
        if (
          data &&
          data.memory_size > 1 &&
          data?.portal_data?.data_value_index?.enabled
        ) {
          // can not set throught dataValueIndex since data is not yet updated
          control.data_value_index = {
            ...(data?.portal_data?.data_value_index || DftDataValueIndex())
          };
        }
        // END data value index
      }
      control.data_id = dataId;
      control.connector_id =
        connectorId !== null && !isNaN(connectorId)
          ? parseInt(connectorId)
          : connectorId;
      this.$emit("input", control);
    },
    update(newVal) {
      this.$emit("input", newVal);
    },
    itemTitle(item) {
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (
        (ctrl && ctrl.template.title) || item.synopticComponent.componentName
      );
    },
    buildExpression(source) {
      if (this.$refs.expForm) {
        this.$refs.expForm.buildExpression(source);
      }
      let control = {
        ...this.value,
        format: ""
      };
      this.update(control);
    }
  },
  beforeCreate() {
    this.$root.$on("simulation:update", (dataId) => {
      if (
        this.selectedData &&
        this.selectedData.id == dataId &&
        this.testDataValue.disabled
      ) {
        this.testDataValue.counter += 1;
      }
    });
  },
  beforeDestroy() {
    this.testDataValue.disabled = false;
    this.$root.$off("simulation:update");
  }
};
</script>

<style scoped>
#process-area-permissions {
  margin-top: 10px;
}

.me {
  margin-bottom: 12px;
}
</style>
